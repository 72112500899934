/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "~bootstrap/dist/css/bootstrap.min.css";
@import "./theme/animation.css";

@import "~swiper/scss";
@import "~@ionic/angular/css/ionic-swiper";

@font-face {
    font-family: "Lato";
    src: url("./assets/fonts/Lato-Bold.ttf") format("opentype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url("./assets/fonts/Lato-Regular.ttf") format("opentype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Lato";
    src: url("./assets/fonts/Lato-Regular.ttf") format("opentype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

.primary-input {
    text-indent: 5px;
    width: 100%;
    --background: rgba(216, 214, 214, 0.562);
    line-height: 28px;
    border-radius: 5px;
    border-style: solid;
    border-color: transparent;
}

ion-toolbar {
    --background: blue;
}
.index {
    position: absolute;
    right: 20px;
    bottom: 20px;
    z-index: 100;
    background: rgba(51, 51, 51, 0.7);
    color: #fff;
    font-size: 12px;
    height: 20px;
    padding: 0px 10px;
    border-radius: 4px;
}

::ng-deep ion-modal .modal-wrapper {
    --height: 90% !important;
    position: absolute;
    top: 5%;
    --width: 90%;
    border-radius: 250px;
    display: block;
}
.productCard {
    display: inline-block;
    width: 32%;
    color: white;
    text-decoration: none;
    margin-left: -0.001px;
    position: relative;
    white-space: initial;

    margin-right: 15px;
}

.text-content {
    display: flex;
    gap: 10px;
}

s {
    color: #616161;
    font-weight: bold;
    font-size: 13px;
}

.thumb {
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    height: 120px;
}

.amount {
    color: #222222;
    font-size: 12px;
    font-weight: lighter;

    margin-top: -12px;
}

.description {
    color: #222222;
    font-size: 13px;
    margin-top: -12px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.price {
    font-size: 14px !important;
    font-weight: bold !important;
    color: black;
}

.product-title {
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    height: 40px;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.product-container {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.header-cards {
    margin: 0px !important;
    padding-bottom: 0px !important;
}

.ngx-slide-confirm-wrapper  {
    background: #019964 !important;
    width: 90%;
    max-width: 400px;
    border: 1px solid #ddd;
}
.ngx-slide-confirm-handle {
    border: 1px solid #ddd;

    .slide-icon {
        color: #019964 !important;
    }
}
.ngx-slide-confirm-boundry-wrapper {
    margin-top: -4px;
    margin-left: -5px;
}

@media (max-width: 800px) {
    .price{
        font-size: 13px !important;
    }
}

body.scanner-active {
    --background: transparent;
    --ion-background-color: transparent;
  }
  

  .new {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 12px;
    color: #019964;
    background-color: #0199644a;
    padding: 3px;
    border-radius: 10px;
    z-index: 100;
}