// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #019964;
  --ion-color-primary-rgb: 235, 68, 90;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #019964;
  --ion-color-primary-tint: #019964;

  /** secondary **/
  --ion-color-secondary: #592581;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #592581;
  --ion-color-secondary-tint: #592581;

  /** tertiary **/
  --ion-color-tertiary: #fab526;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #fab526;
  --ion-color-tertiary-tint: #fab526;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}

body {
  background: none !important;
  font-family: Rocgrotesk, sans-serif;
}

:root {
  --ion-font-family: Rocgrotesk, sans-serif !important;
}

.no-background-toolbar {
  --background: transparent;
  --ion-color-base: transparent !important;
}

.product-detail {
  background-color: whitesmoke !important;
  --background: whitesmoke !important;
  --max-width: 750px;  
}

.body-section {
  border-radius: 10px;
  padding: 6px 10px;
  background: #fff;
}

.middle-notice {
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
  color: #333;

  p {
    font-size: 14px;
  }

  ion-button {
    width: 100%;
  }
}

ion-toolbar {
  --background: whitesmoke !important;
}

.payment-methods-modal {
  border-radius: 10px 10px 0px 0px;
  --height: 370px;
}

/**
* Hide Google maps controls
*/
.gm-svpc,
.gmnoprint,
.gm-fullscreen-control {
  display: none;
}

.price {
  .discount-strikethrough {
    text-decoration: line-through;
    font-weight: normal;

    color: #414141;
  }

  .discount-price {
    color: #019964;
    margin-left: 5px;
    font-weight: bold;
  }
}

@media only screen and (min-width: 768px) and (min-height: 600px) {
  .big-modal {
    --width: 800px;
    --height: 600px;
  }

  .payment-methods-modal {
    --width: 500px;
  }
}

.width-auto {
  width: auto !important;
}

.category-detail-page {
  .productCard {
    margin-right: 0px !important;
    margin-left: 0px !important;
    width: 100% !important;

    img {
      height: 30vw;
      max-height: 200px;
    }
  }
}

.address-detail {
  --width: 325px !important;
  --height: auto !important;
  --backdrop-opacity: 0.6 !important;
}
